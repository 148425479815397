import React from "react";
import './card.css'

function Card({ card, handleCardClick, styleName, cardNameClass }) {

	const cardClick = () => {
		handleCardClick(card);
	};

	return (
		<article className="place">
			<button
				type="button"
				className="button place__image-button popup__save_condition_hover"
				onClick={cardClick}
			>
				<img src={card.img} alt={card.name} className={`card__image ${styleName}`} />
        <div className={`card__name ${cardNameClass}`}>{card.text}</div>
			</button>
		</article>
	);
}

export default Card;
